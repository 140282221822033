
/// [ GLOBAL VARIABLES ]
var modal = document.getElementById("auth-modal");
var section_login = document.getElementById("section-login");
var section_register = document.getElementById("section-register");

/// [ MAIN VARIABLES ]
btns_modal_login = document.querySelectorAll(".auth-modal-login");
btns_modal_register = document.querySelectorAll(".auth-modal-register");
btns_section_login = document.querySelectorAll(".auth-section-login");
btns_section_register = document.querySelectorAll(".auth-section-register");
login_error = section_login.querySelector("span.small.text-danger");
register_error = section_register.querySelector("span.small.text-danger");

/// [ SET EVENT LISTENERS ]
btns_modal_login.forEach(function(btn_modal_login) {
  btn_modal_login.addEventListener("click", function() {
    toggle_section(section_login);
    toggle_modal();
  });
});
btns_modal_register.forEach(function(btn_modal_register) {
  btn_modal_register.addEventListener("click", function() {
    toggle_section(section_register);
    toggle_modal();
  });
});
btns_section_login.forEach(function(btn_section_login) {
  btn_section_login.addEventListener("click", function() {
    toggle_section(section_login);
  });
});
btns_section_register.forEach(function(btn_section_register) {
  btn_section_register.addEventListener("click", function() {
    toggle_section(section_register);
  });
});
if(login_error) {
  toggle_section(section_login);
  toggle_modal();
}
if(register_error) {
  toggle_section(section_register);
  toggle_modal();
}

/// [ FUNCTIONS ]
function toggle_modal() {
  $(modal).modal('show');
}

function toggle_section(section) {
  if(section == section_login) {
    $(section_register).collapse('hide');
  } else {
    $(section_login).collapse('hide');
  }
  $(section).collapse('show');
}
